import {
  handleError,
  getHeaderInfo,
  getEndpoint
} from './apiUtils';

export async function getExpiryStatus(secureID) {
  return fetch(getEndpoint(`patient-intake/${secureID}`), getHeaderInfo())
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function validateUser(secureID, formData) {
  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
  };
  const params = {
    method,
    headers: fixedHeaders,
    body: JSON.stringify(formData),
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/validate`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function getDocuments(secureID, securedToken) {
  const { method, headers } = getHeaderInfo();
  const fixedHeaders = {
    ...headers,
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
    redirect: 'follow'
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/get-documents`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function fetchDocument(secureID, securedToken, docId) {
  const { method, headers } = getHeaderInfo();
  const fixedHeaders = {
    ...headers,
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/fetch-document/${docId}`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function uploadDocuments(secureID, securedToken, formData) {
  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
    body: JSON.stringify(formData),
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/upload-documents`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function getInsurancePlans(secheduleID) {
  return fetch(getEndpoint(`schedules/${secheduleID}/insurance-plans`), getHeaderInfo())
    .then((resp) => resp?.json())
    .catch(handleError);
}

function getHeaderInfoForDocs(method = 'POST') {
  const headerInfo = {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.api+json',
  };

  return { method, headers: headerInfo };
}

const fixedHeaders = {
  'Content-Type': 'application/vnd.api+json',
  Authorization: `api-key ${process.env.REACT_APP_OCR_APIKEY}`,
  accept: '*/*',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Request-Headers': 'authorization',
  'Access-Control-Request-Method': 'POST',
};

export const FetchFormRecognizerAPI = async (fileName, base64FileContent) => {
  var fileContent = base64FileContent?.split("base64,")?.pop();
  const { method } = getHeaderInfoForDocs('POST');
  const payload = {
    "filename": fileName,
    "fileContent": fileContent
  };
  const params = {
    method,
    headers: fixedHeaders,
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    process.env.REACT_APP_INSURANCEOCR_URL, params
  );
  return await response?.text();

}

export async function calcCopay(secureID, securedToken, formData) {
  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
    body: JSON.stringify(formData),
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/calculate-copay`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function getPatientInfo(secureID, securedToken) {
  const { method, headers } = getHeaderInfo();
  const fixedHeaders = {
    ...headers,
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
    redirect: 'follow'
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/confirmation`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function submitInfo(secureID, securedToken) {
  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/submit`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function getInsuranceInfo(secureID, securedToken) {
  const { method, headers } = getHeaderInfo();
  const fixedHeaders = {
    ...headers,
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
    redirect: 'follow'
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/insurance-info`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function updateInsuranceInfo(secureID, securedToken, formData) {
  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
    body: JSON.stringify(formData),
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/insurance-info`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function payLater(secureID, securedToken) {
  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/decline-copay`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function createTransaction(secureID, securedToken) {
  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/transaction`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function confirmTransaction(secureID, securedToken, formData) {
  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
    body: JSON.stringify(formData),
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/process-transaction`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function fetchForm(secureID, securedToken, formType) {
  const { method, headers } = getHeaderInfo();
  const fixedHeaders = {
    ...headers,
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/fetch-form/${formType}`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function saveForm(secureID, securedToken, formType, formData) {
  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
    body: JSON.stringify(formData),
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/save-form/${formType}`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function fetchAllFormaData(secureID, securedToken) {
  const { method, headers } = getHeaderInfo();
  const fixedHeaders = {
    ...headers,
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/form-data`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function savePDF(secureID, securedToken, formType, pdfBlob) {
  const formData = new FormData();
  formData.append("PdfFile", pdfBlob, "test.pdf");

  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    'Authorization': headers.Authorization,
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
    body: formData,
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/save-pdf/${formType}`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function completeDriverLicense(secureID, securedToken, step) {
  const { method, headers } = getHeaderInfo('PUT');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/complete-step/${step}`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}

export async function fetchAutoPopulate(secureID, securedToken, formType) {
  const { method, headers } = getHeaderInfo();
  const fixedHeaders = {
    ...headers,
    'X-Secure-Token': securedToken,
  };
  const params = {
    method,
    headers: fixedHeaders,
  };
  return fetch(getEndpoint(`patient-intake/${secureID}/auto-populate/${formType}`), params)
    .then((resp) => resp?.json())
    .catch(handleError);
}